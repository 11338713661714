import { Nav, Navbar, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FaHome } from "react-icons/fa";
import { NavLink } from "react-router-dom";

import * as Navigate from "../../constants/routes";
import swiftTransportLogo from "../../images/swift-transport-logo.png";
import { selectServerEnvironment } from "../../rtk-slice/globalSlice";
import UserProfileDetails from "./ProfileDetails";
import "./Navbar.scss";
import { hasModuleAccess } from "../../utils";
import {
  AUTO_RUNSHEET,
  DRIVERS,
  EXPORT_RELEASE_PLANNER,
  INDUCTION,
  INDUCTION_LEARNING,
  INSPECTION_REPORT,
  MANUAL_RUNSHEET,
  REPORTING_TOOL,
  SURCHARGE_MAINTENANCE,
  USER_MANAGEMENT,
  VEHICLES,
} from "../../constants/enums";

function NavbarComp() {
  const environment = useSelector(selectServerEnvironment);

  const modules = [
    hasModuleAccess(USER_MANAGEMENT) && {
      title: "User Mangement",
      linkTo: Navigate.USER_MANAGEMENT,
    },
    hasModuleAccess(MANUAL_RUNSHEET) && {
      title: "Manual Runsheet",
      linkTo: Navigate.MANUAL_RUNSHEET,
    },
    hasModuleAccess(AUTO_RUNSHEET) &&
    ["dev", "local"].includes(environment) && {
      title: "Auto Runsheet",
      linkTo: Navigate.AUTO_RUNSHEET,
    },
    hasModuleAccess(INDUCTION) && {
      title: "Hire",
      linkTo: Navigate.INDUCTION,
    },
    hasModuleAccess(INSPECTION_REPORT) && {
      title: "Inspection Report",
      linkTo: Navigate.INSPECTION_REPORT,
    },
    hasModuleAccess(VEHICLES) && {
      title: "Vehicles",
      linkTo: Navigate.VEHICLES,
    },
    hasModuleAccess(DRIVERS) && {
      title: "Drivers",
      linkTo: Navigate.DRIVERS_DASHBOARD,
    },
    hasModuleAccess(EXPORT_RELEASE_PLANNER) && {
      title: "Export Release Planner",
      linkTo: Navigate.EXPORT_RELEASE_PLANNER,
    },
    hasModuleAccess(INDUCTION_LEARNING) && {
      title: "Induction Learning",
      linkTo: Navigate.INDUCTION_LEARNING,
    },
    hasModuleAccess(REPORTING_TOOL) && {
      title: "Reporting Tool",
      linkTo: Navigate.REPORTING,
    },
    hasModuleAccess(SURCHARGE_MAINTENANCE) && {
      title: "Surcharge Maintenance",
      linkTo: Navigate.SURCHARGE_MAINTENANCE,
    },
  ];

  return (
    <>
      {/* This is the top Header. */}
      <Navbar bg="" expand="lg" className="navbar--Header">
        <Container className="py-1 navbar--container">
          <NavLink className="nav-link me-auto" to={Navigate.HOME}>
            <img
              src={swiftTransportLogo}
              width="110"
              height="80"
              className="d-inline-block align-top me-5"
              alt="React Bootstrap logo"
            />
          </NavLink>
          <UserProfileDetails />
        </Container>
      </Navbar>

      {/* Navbar */}
      <Navbar bg="light" expand="lg" className="navbar">
        <Container className="navbar--container">
          <span className="navbar-brand">
            <NavLink className="nav-link " to={Navigate.HOME}>
              <FaHome className="me-5" color="black" size={20} />
            </NavLink>
          </span>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {modules.map((module, index) =>
                module ? (
                  <NavLink
                    key={index}
                    className="nav-link me-2"
                    to={module.linkTo}
                  >
                    {module.title}
                  </NavLink>
                ) : null
              )}
            </Nav>
            {["dev", "local"].includes(environment) && (
              <NavLink className="nav-link me-2" to={Navigate.ENIGMA_CHAT}>
                Enigma Chat
              </NavLink>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarComp;
