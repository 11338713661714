import React, { useMemo } from "react";
import { DownloadDocumentButton, getExpirationColor } from "../Index";
import config from "../../../constants/config";
import TableComp from "../../../components/Table";
import { ColumnFilter } from "../../../components/Table/Filters";
import {
  NoteCell,
  wrapCell,
} from "../../SurchargeMaintenance/components/CommonCell";
import { useSelector } from "react-redux";
import { getDrivers } from "../../../rtk-slice/globalSlice";
import { driversTableClickEvents } from "../utils";
import { generateDriversEditViewDeleteColumn } from "../../../utils";

function DocumentSignOff() {
  const drivers = useSelector(getDrivers);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "employeeId",
        Filter: ColumnFilter,
      },
      {
        Header: "Name",
        accessor: "fullName",
        Filter: ColumnFilter,
        width: 150,
        Cell: ({ value }) => NoteCell(value),
      },
      {
        Header: "Driver Type",
        accessor: "driverTypeName",
        Filter: ColumnFilter,
      },
      {
        Header: "License No.",
        accessor: "licenseNo",
        Filter: ColumnFilter,
      },
      {
        Header: "License Exp. Status",
        accessor: "licenseExpiryDateStr",
        Filter: ColumnFilter,
        Cell: ({ value }) => {
          const { color, tooltip, background } = getExpirationColor(value);
          return (
            <div
              className="d-flex justify-content-center align-items-center py-2"
              style={{ color, background, width: " 100%" }}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={value}
            >
              {tooltip}
            </div>
          );
        },
      },
      {
        Header: "Contact No.",
        accessor: "contactNo",
        Filter: ColumnFilter,
      },
      {
        Header: wrapCell("Risk Assessment sign off"),
        accessor: "riskAssessmentSignedOff",
        disableFilters: true,
        width: 100,
        Cell: ({ row: { original } }) => (
          <DownloadDocumentButton
            docName={original?.riskAssessmentSignedOff}
            userId={original?.userId}
            url={
              config.userDocuments.downloadFile +
              "/" +
              original?.userId +
              "/" +
              "risk-assesst-sign-off.pdf"
            }
            fileName="risk-assesst-sign-off.pdf"
          />
        ),
      },
      {
        Header: wrapCell("Safety Alert sign off"),
        accessor: "safetyAlertDocSignedOff",
        disableFilters: true,
        width: 100,
        Cell: ({ row: { original } }) => (
          <DownloadDocumentButton
            docName={original?.safetyAlertDocSignedOff}
            userId={original?.userId}
            url={
              config.userDocuments.downloadFile +
              "/" +
              original?.userId +
              "/" +
              "safety-alert-sign-off.pdf"
            }
            fileName="safety-alert-sign-off.pdf"
          />
        ),
      },
      {
        Header: wrapCell("PPE Requirements sign off"),
        accessor: "ppedocSignedOff",
        disableFilters: true,
        width: 100,
        Cell: ({ row: { original } }) => (
          <DownloadDocumentButton
            docName={original?.ppedocSignedOff}
            userId={original?.userId}
            url={
              config.userDocuments.downloadFile +
              "/" +
              original?.userId +
              "/" +
              "ppe-doc-sign-off.pdf"
            }
            fileName="ppe-doc-sign-off.pdf"
          />
        ),
      },
      {
        Header: "SWIFT TMP",
        accessor: "trafficRulesSignedOff",
        disableFilters: true,
        width: 100,
        Cell: ({ row: { original } }) => (
          <DownloadDocumentButton
            docName={original?.trafficRulesSignedOff}
            userId={original?.userId}
            url={
              config.userDocuments.downloadFile +
              "/" +
              original?.userId +
              "/" +
              "traffic-mng-sign-off.pdf"
            }
            fileName="traffic-mng-sign-off.pdf"
          />
        ),
      },
      generateDriversEditViewDeleteColumn(
        "userId",
        false,
        driversTableClickEvents
      ),
    ],
    []
  );
  return (
    <div>
      <TableComp columns={columns} data={drivers} />
    </div>
  );
}

export default DocumentSignOff;
