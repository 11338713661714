const config = {
  hostsEnvironment: {
    "localhost:3000": "local",
    "dev.trackmile.com.au/api": "dev",
    "uat.trackmile.com.au": "uat",
    "trackmile.com.au": "prod",
  },
  baseUrl:
    process.env.NODE_ENV === "development"
      ? "https://dev.trackmile.com.au/api"
      : "/api",
  inductionBaseUrl:
    process.env.NODE_ENV === "development"
      ? "https://dev.trackmile.com.au/induction"
      : "/induction",
  baseUrlInductionLearning:
    process.env.NODE_ENV === "development"
      ? "https://induction-backend-dev.swifttrackmile.codes"
      : "https://induction-backend.trackmile.com.au",
  exportReleasePlanner: {
    upload: "/export/release/planner/upload",
    allFiles: "/export/release/planner/all/files",
    document: "/export/release/planner/document/",
    updateRow: "/export/release/planner/row/update",
    deleteRow: (rowNum, id) =>
      `/export/release/planner/row/delete?rowNum=${rowNum}&exportReleasePlannerId=${id}`,
  },
  goLiveMonitoring: {
    base: "https://portal.golivemonitoring.com",
    loginEndpoint: "/?/Login/procLogin/",
    secret: "qqAdRTYTeLB7pPMcpty887dT",
    userName: "manisha@swifttrans.com.au",
  },
  reports: {
    user: "/reports/allUserDetails/uploadExcelFile",
  },
  getDriverList: "/getDriverList",
  findRemitter: "/findRemitter",
  getUserList: "/getUserList",
  getAllUserList: "/getAllUserList",
  getDriverGroupList: "/lookUp/getDriverGroupList",
  getVehicleTypeList: "/lookUp/getVehicleType",
  getLookUpValues: "/lookUp",
  getAllLookUps: "/lookUp/all",
  getAllUserReportFile: "/reports/allUserDetails/download",
  getDriverTypeList: "/lookUp/getDriverTypeList",
  getProfileList: "/getProfileList",
  deleteUser: "/deleteUser",
  activateUser: "/activateUser",
  login: "/login",
  otpLogin: "/otp-login",
  sendOtp: "/send-otp",
  inductionChecklist: "/admin/file/checklist",
  logout: "/logout",
  saveUser: "/saveUser",
  uploadProfilePicture: "/uploadProfilePic",
  getProfilePicture: "/getProfilePic",
  saveDriverRates: "/rateDetails/saveDriverRateDtl",
  saveOrUpdateRunsheet: "/runsheet/manual/saveOrUpdate",
  deleteRunsheets: "/runsheet/manual/remove",
  getRunsheetSummary: "/runsheet/manual/summary",
  getDateRangeRunsheets: "/runsheet/manual/date-range-runsheets",
  verifyRunsheetsHours: "/runsheet/manual/verified-hours",
  getApprovedRunsheets: "/runsheet/manual/reports/approved",
  getVerifiedRunsheets: "/runsheet/manual/reports/verified",
  approveRunsheets: "/runsheet/manual/approve",
  submitRunsheet: "/runsheet/manual/submit",
  downloadReports: "/runsheet/manual/reports/download",
  getAdvancedSearchResults: "/runsheet/manual/advanced-search",
  getManualRunsheetWeeks: "/runsheet/manual/manual-runsheet-weeks",
  updateUser: "/updateUser",
  getDriverCurrentRates: "/rateDetails/getDriverCurrentRates",
  changePassword: "/changePassword",
  saveDriverDeductions: "/deductionDetails/saveDriverDeductionDetails",
  getDriverDeductions: "/deductionDetails/getDriverDeductionDetails",
  getDriverDeductionsHistory:
    "/deductionDetails/getDriverDeductionAmtHistoryDetails",
  driverAdditionsUrls: {
    getDriverCardByDriverIdUsingGET:
      "/transports/driver/card/additionalDetails/",
    saveDriverAdditions: "/additionDetails/saveDriverAdditionDetails",
    getDriverAdditions: "/additionDetails/getDriverAdditionDetails",
    getDriverAdditionsHistory:
      "/additionDetails/getDriverAdditionAmtHistoryDetails",
    resetDriverAdditionDetails: "/additionDetails/resetAdditionDetails",
  },
  driverTfnUrls: {
    saveDriverTfnDetails: "/tfn/save-or-update",
    getDriverTfnDetails: "/tfn",
  },
  userDocuments: {
    getAllFiles: "/document",
    uploadFile: "/document/upload",
    deleteFile: "/document/delete",
    downloadFile: "/document/download",
  },
  getAutoRunsheetWeeks: "/runsheet/auto/auto-runsheet-weeks",
  getAutoRunsheetSummary: "/runsheet/auto/summary",
  verifyAutoRunsheetHours: "/runsheet/auto/verified-hours",
  getApprovedAutoRunsheets: "/runsheet/auto/reports/approved",
  getVerifiedAutoRunsheets: "/runsheet/auto/reports/verified",
  approveAutoRunsheets: "/runsheet/auto/approve",
  downloadAutoRunsheetReports: "/runsheet/auto/reports/download",
  getAutoRunsheetAdvancedSearchResults: "/runsheet/auto/advanced-search",
  enigmaChat: {
    getAllUsers: "/enigma-chat/all/active/users",
    getUserConversations: "/enigma-chat/conversation/all/",
    createConversation: "/enigma-chat/conversation/create",
    getChatConversation: "/enigma-chat/conversation/get",
    sendMessage: "/enigma-chat/conversation/participant/publish-chat-message",
    removeParticipant: "/enigma-chat/conversation/participant/remove",
  },
  inspectionReport: {
    searchByFilter: "/inspection-report/search",
    updateInspectionStatus: "/inspection-report/update-status",
  },
  vehicles: {
    base: "/transports/vehicle",
    dashboard: {
      general: "/transports/vehicle/dashboard/generals",
      getAllVehicleCardsTabsInfo: "/transports/vehicle/dashboard/alltabsInfo",
    },
  },
  sendSmsToUserProfile: "/sendSms/profile/",
  sendSmsToSingleUser: "/sendSms",
  getDriverDeclarations: "/declarations?driverId=",
  saveDriverDeclarations: "/declarations/save-or-update?driverId=",
  getInductionAllDocs: "/induction-documents/",
  downloadInductionDoc: "/induction-documents/downloads/{filename}?filename=",
  surchargeMaintenance: {
    venderAll: "/surcharge/vendor-rate/all",
    addVendor: "/surcharge/vendor-rate/",
    vendorHistory: "/surcharge/vendor-rate-history/",
    clientGroupId: "/surcharge/client-group/by-rate-code/",
    createClientGroup: "/surcharge/client-group/",
    clients: "/surcharge/client-rate/by-vendor-rate-code/",
    CreateClients: "/surcharge/client-rate/",
    clientHistory: "/surcharge/client-rate-history/",
    department: "/surcharge/department",
    allDepartment: "/surcharge/department/all",
  },
  sfCustomer: "/sfa/customer",
  sfCustomerSignature: "/sfa/customer/signature",
  AdminSfCustomer: {
    Customer: "/sfa/admin/customer/",
    updateCustomer: "/sfa/admin/customer/update",
  },
};

export default config;
