export const HOME = "/";
export const LOGIN = "/login";
export const PRIVACY_POLICY = "/privacy-policy";
export const CREDIT_APPLICATION = "/credit-application";
export const CREDIT_APPLICATION_WITH_ID = "/credit-application/:id";

export const MANUAL_RUNSHEET = "/manual-runsheet";
export const AUTO_RUNSHEET = "/auto-runsheet";

export const USER_MANAGEMENT = "/user-management";
export const INDUCTION = "/induction";
export const CREATE_USER = "create-user";
export const CREATE_INDUCTION_USER = "create-induction-user";
export const INSPECTION_REPORT = "inspection-report";
export const ENIGMA_CHAT = "enigma-chat";

export const VEHICLES = "vehicles";
export const VEHICLES_ADVANCE_SEARCH = "/vehicles/advance-search";
export const VEHICLE_ACTION = ":actionType/:vehicle/:id";

export const DRIVERS_DASHBOARD = "/drivers/dashboard";
export const DRIVERS_OPERATIONS = "/drivers-operation/:viewMode/:userId";

export const EXPORT_RELEASE_PLANNER = "/export-release-planner";
export const EXPORT_RELEASE_PLANNER_ACTION =
  "/export-release-planner/:action/:id";

export const INDUCTION_LEARNING = "/induction-learning";
export const INDUCTION_LEARNING_SCREENS = "/induction-learning/screen";
export const INDUCTION_LEARNING_VEHICLE = "/induction-learning/vehicle";
export const INDUCTION_LEARNING_JOBS = "/induction-learning/jobs";
export const INDUCTION_LEARNING_RUNSHEETS = "/induction-learning/runsheets";
export const INDUCTION_LEARNING_CONTAINER = "/induction-learning/container";
export const INDUCTION_LEARNING_CUSTOMER = "/induction-learning/customer";
export const INDUCTION_LEARNING_ALLOCATORS = "/induction-learning/allocators";
export const INDUCTION_LEARNING_YARDS = "/induction-learning/yards";
export const INDUCTION_LEARNING_SEARCH_SCREEN =
  "/induction-learning/search-screen";
export const INDUCTION_LEARNING_DELETE_MODULE =
  "/induction-learning/delete-module";
export const REPORTING = "/reporting";
export const SURCHARGE_MAINTENANCE = "/surcharge-maintenance";
export const FINANCE_APPLICATION = "/finance-application/";
export const CUSTOMERS = "customers";
export const FINANCE_APPLICATION_CUSTOMER = FINANCE_APPLICATION + CUSTOMERS;
export const EditCreditApplication = "credit-application/:id";
export const VIEW_CREDIT_APPLICATION_WITH_ID = "view/credit-application/:id";
export const FINANCE = "finance";
export const SWIFT_TRAFFIC_SIGNUP = "swift-traffic-signup";
export const RISK_ASSESSMENT_SIGNUP = "risk-Assessment-signup";
export const SAFETY_ALERT_SIGNUP = "safety-alert-signup";
export const PPE_REQUIREMENTS = "ppe-requirements-signup";
